import request from "@/api/request";

/**
 * 申报列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepDeclareList(params) {
    return await request.post("/keep/getKeepDeclareList",params)
}

/**
 * 申报详情
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepDeclare(params) {
    return await request.post("/keep/getKeepDeclare",params)
}


/**
 * 申报详情 - 修改状态
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updKeepDeclare(params) {
    return await request.post("/keep/updKeepDeclare",params)
}


/**
 * 去申报 - 列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepListByStatus(params) {
    return await request.post("/keep/getKeepListByStatus",params)
}

/**
 * 去申报 - 添加
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addKeepDeclare(params) {
    return await request.post("/keep/addKeepDeclare",params)
}


/**
 * pc查询所有申报记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepDeclarePageList(params) {
    return await request.post("/keep/getKeepDeclarePageList",params)
}
