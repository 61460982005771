import {Message, MessageBox, Loading,Notification } from 'element-ui';

var MessageUtils = {
    successMsg(text) {
        Message({
            message: text,
            type: 'success',
            duration: 2000,
            showClose: true,
            customClass: 'messageIndex'
        })
    },
    errorMsg(text) {
        Message({
            message: text,
            type: 'error',
            duration: 2000,
            showClose: true,
            customClass: 'messageIndex'

        })
    },
    warningMsg(text) {
        Message({
            message: text,
            type: 'warning',
            duration: 2000,
            showClose: true,
            customClass: 'messageIndex'
        })
    },
    infoMsg(text) {
        Message({
            message: text,
            type: 'info',
            duration: 2000,
            showClose: true,
            customClass: 'messageIndex'
        })
    },
    notification(text,type,position) {
        Notification.success({
            title: '提示',
            message: text,
            type: type,
            customClass: 'messageIndex',
            position:position?position:'top-right',
        })
    },
    confirmMsg(text) {
        return new Promise((resolve) => {
            MessageBox.confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resolve(true)
            }).catch(() => {
                resolve(false);
            });
        }).catch(() => {

        })
    },
    loadingMsg(text) {
        return Loading.service({
            lock: true,
            text: text,
            spinner: 'fa fa-spinner animated faa-spin',
            background: 'hsla(0,0%,100%,.9)'
        });
    }
}

export default MessageUtils;


