<template>
  <el-container>
    <el-header style="margin: 0;padding: 0">
      <div class="navbar">
        <div>
          <el-menu :default-active="$route.path" mode="horizontal" class="el-menu-demo" :router="true">
            <el-menu-item style="margin: 0 100px 0 100px"><img src="@/assets/logo.png" style="width: auto;height: 60px">
            </el-menu-item>
            <el-menu-item index="/cost-list">记账</el-menu-item>
            <el-menu-item index="/tax-payment-list">申报</el-menu-item>
            <div style="float: right;line-height: 80px;margin-right: 30px">
              <el-dropdown trigger="click"  @command="logoutClick">
                <el-link type="primary"  class="el-dropdown-link">
                  {{ userName === 'null'?'未实名用户':handeleStr(userName) }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-link>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="iconfont icon-tuichudenglu" command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-menu>

        </div>

      </div>
    </el-header>
    <el-main style="margin-top: 10px">
      <router-view/>
    </el-main>
  </el-container>


</template>

<script>
import MessageUtils from "@/utils/elementMessageUtils";
import {updKeepDeclare} from "@/api/declare/declare";
import {getUserName, remove} from "@/utils/authUtils";

export default {
  name: "Home",
  data() {
    return {
      userName:'',
      activeIndex: '/cost-list',
    }
  },
  methods: {
    logoutClick(val) {
      if (val === 'logout') {
        MessageUtils.confirmMsg('你确定要退出登录吗？').then(resolve => {
          if (resolve) {
            remove();
            localStorage.clear();
            sessionStorage.clear();
            this.$router.replace("/login-p");
          }
        })
      }
    },
    handeleStr(str) {
      if (str) {
        let length = str.length
        let newArr = str.split('');
        newArr[str.length - 1] = '*';
        return newArr.join('');
      }
    },
  },
  mounted() {
    this.userName = getUserName();
    document.title = this.$route.meta.title
  }
}
</script>

<style>
.navbar {
  padding: 0;
  margin: 0 auto;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid red !important;
  color: #303133;
}
</style>
<style scoped>
.el-menu-demo {
  height: 80px;
}

.el-menu-item {
  line-height: 80px;
  font-size: 25px;
  font-weight: bold;
}


</style>
